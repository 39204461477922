<template>
  <section class="payment container">
    <div class="payment-box">
      <h2 class="payment__h2 text-center">Политика конфиденциальности</h2>
      <p>
        Настоящая Политика конфиденциальности данных (далее – Политика
        конфиденциальности) действует в отношении данных, которые АО «ForteBank»
        (БИН 980 341 000 286, г. Астана, ул. Достык, 8/1) может получить с
        использованием веб-сайта, расположенного в сети Интернет на доменном
        имени: https://www.tahit.kz. Общество обрабатывает данные пользователей
        Сайта исключительно в рамках требований Законодательства Республики
        Казахстан, в том числе Закона Республики Казахстан от 21 мая 2013 года №
        94-V «О персональных данных и их защите», а также международных
        договоров, ратифицированных Республики Казахстан. Политика
        конфиденциальности (далее — Политика) разработана в соответствии с их
        нормами. Использование Пользователем Сайта означает согласие с Политикой
        конфиденциальности и условиями обработки данных. В случае несогласия с
        условиями Политики конфиденциальности Пользователь должен прекратить
        использование Сайта. Настоящая Политика предназначена для обеспечения
        надлежащей защиты информации о Пользователях, в том числе их
        персональных данных, от несанкционированного доступа и разглашения.
        Политика, а также другая официальная документация Сайта и действующее
        законодательство Республики Казахстан регулируют отношения, связанные со
        сбором, хранением, распространением и защитой информации о пользователях
        Сайта. В случае наличия противоречий между настоящей Политикой и иными
        официальными документами Сайта применению подлежит настоящая Политика.
        Пользователи должны знать, что при переходе на некоторые ссылки,
        размещённые на Сайте, они могут быть перенаправлены к сайтам
        (приложениям и т.д.) других компаний за пределы хостингового
        пространства АО «ForteBank» («Facebook», «google+», «ВКонтакте), где
        информация о Пользователях собирается вне прямого контроля АО
        «ForteBank» и Администрации Сайта. В таком случае Политики
        конфиденциальности сайтов и/или приложений третьих лиц будут
        регулировать порядок обработки информации, полученной от пользователей
        этими третьими лицами. АО «ForteBank» и Администрация Сайта не несут
        ответственности за переданную Пользователем этим третьим лицам
        информацию. Если Пользователь решил войти на сайт, используя службу
        аутентификации стороннего оператора, например: информацию Facebook,
        Общество может получить дополнительный профиль, или другую информацию,
        доступ к которой разрешен Пользователем в аккаунте стороннего оператора
        для предоставления третьим лицам.
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">Определение терминов</h2>
      <p>
        В Политике конфиденциальности используются следующие термины:
        «Администрация сайта» — уполномоченные сотрудники, действующие от имени
        АО «ForteBank», которые организуют и (или) осуществляют Обработку
        конфиденциальных данных, а также определяет цели обработки
        конфиденциальных данных, состав конфиденциальных данных, подлежащих
        обработке, действия (операции), совершаемые с конфиденциальными данными.
        Конфиденциальные данные — данные, оставляемые Пользователем сайта в
        формах обратной связи и заявок на подключение услуг, Cookies, а также
        автоматически передаваемые данные в процессе использования сайта, в том
        числе, но не ограничиваясь: IP-адрес, сведения об устройстве, с которого
        осуществляется доступ и т.д. «Персональные данные» — сведения,
        относящиеся к определенному или определяемому на их основании субъекту
        персональных данных, зафиксированные на электронном, бумажном и (или)
        ином материальном носителе.
      </p>
      <p>
        «Обработка персональных данных» — любое действие (операция) или
        совокупность действий (операций), совершаемых с использованием средств
        автоматизации или без использования таких средств с персональными
        данными, включая сбор, запись, систематизацию, накопление, хранение,
        уточнение (обновление, изменение), извлечение, использование, передачу
        (предоставление, доступ), обезличивание, блокирование, удаление,
        уничтожение персональных данных. «Конфиденциальность персональных
        данных» — обязательное для соблюдения Оператором или иным получившим
        доступ к персональным данным лицом требование не допускать их
        распространения без согласия субъекта персональных данных или наличия
        иного законного основания. «Пользователь» сайта
        <a href="#" class="payment-mail"> https://www.tahit.kz.</a> — лицо,
        имеющее доступ к Сайту посредством сети Интернет и использующее Сайт.
        «Сайт» — веб-сайт, расположенный в сети Интернет на доменном адресе
        <a href="#" class="payment-mail"> https://www.tahit.kz.</a> «Cookies» —
        небольшой фрагмент данных, отправленный веб-сервером и хранимый на
        компьютере Пользователя, который веб-клиент или веб-браузер каждый раз
        пересылает веб-серверу в HTTP-запросе при попытке открыть страницу
        соответствующего сайта. «IP-адрес» — уникальный сетевой адрес узла в
        компьютерной сети, построенной по протоколу IP.
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
        Предмет политики конфиденциальности
      </h2>
      <p>
        Политика конфиденциальности устанавливает обязательства Администрации
        сайта по неразглашению и обеспечению режима защиты конфиденциальности
        данных, которые Пользователь предоставляет Администрации сайта при
        работе с сайтом, в том числе при подаче заявки на подключение.
        Конфиденциальные данные, разрешенные к Обработке в рамках Политики
        конфиденциальности, предоставляются Пользователем путем заполнения
        регистрационной формы (пример представлен ниже), форм обратной связи и
        включают в себя – Наименование Пользователя (указывается по усмотрению
        Пользователя), телефон, почта (адрес электронной почты) Юридического
        лица. Если в форме заявки Пользователь вместо контактных данных
        юридического лица оставляет свои персональные данные, то Общество
        обеспечивает их защиту в соответствии с требованиями Законодательства
        Республики Казахстан о персональных данных. Кроме регистрационной формы
        на Сайте могут использоваться различные системы веб- аналитики. Сайт
        защищает данные, которые автоматически передаются в процессе просмотра
        рекламных блоков и при посещении страниц Сайта, в том числе:
        <ul class="payment-list">
        <br />
        <li> IP-адрес;<br /></li>
        <li> информация из Cookies;<br /></li>
        <li> информация о браузере (или иной программе, которая осуществляет доступ
        к показу рекламы); <br /></li>
        <li>
          операционная система;<br />
        </li>
        <li> время доступа, часовой пояс/регион;<br /></li>
        <li> адрес страницы Сайта.<br /></li>
        </ul>
        Отключение Cookies может повлечь невозможность доступа к некоторым
        разделам Cайта. Администрация сайта может осуществлять сбор статистики в
        отношении данных, указанных в п. 2.3. Политики конфиденциальности.
        Данная информация используется с целью выявления и решения технических
        проблем, для противодействия мошенничеству. Информация подлежит
        надежному хранению и нераспространению, за исключением случаев,
        предусмотренных Политикой конфиденциальности и законодательством
        Республики Казахстан.
      </p>
      <p>
        Общество и Администрация сайта вправе предоставлять информацию
        Пользователей своим аффилированным лицам (в том числе осуществляющих
        свою деятельность за пределами территории Республики Казахстан), а также
        иным компаниям, связанным с Обществом в целях, указанных выше. При этом
        аффилированные лица, а также связанные с ним компании обязаны
        придерживаться положений настоящей Политики. Обществом не
        предоставляется информация Пользователей компаниям и частным лицам, не
        связанным с Обществом, за исключением перечисленных ниже случаев: ·
        Пользователь дал на это свое согласие; · По основаниям и в порядке,
        установленным законодательством Республики Казахстан, в том числе с
        целью: – выполнения судебных решений или исполнения законных требований
        государственных органов; – устранения технических сбоев или проблем
        безопасности; – защиты прав, собственности или безопасности Общества,
        пользователей Сервисов Общества в рамках, допускаемых действующим
        законодательством.
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
        Порядок действия политики конфиденциальности и внесения в нее изменений
      </h2>
      <p>
        Политика вступает в силу на неограниченный срок с момента ее утверждения
        Обществом. Действующая Политика признается недействительной после ее
        замены новой Политикой. Замена Политики осуществляется путем публикации
        ее новой редакции на Сайте. Действующая редакция Политики, являющаяся
        публичным документом, доступна любому пользователю сети Интернет при
        переходе по ссылке
        <a href="#" class="payment-mail"> https://tahit.kz/payment.</a>
        Администрация Сайта вправе вносить изменения в Политику, которые не
        противоречат законодательству Республики Казахстан. Размещение на Сайте
        новой редакции Политики после ее утверждения является уведомлением
        пользователей Сайта о внесенных в Политику изменениях.
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
        Условия и цели обработки конфиденциальных данных
      </h2>
      <p>
        Администрация Сайта осуществляет обработку конфиденциальных данных
        Пользователя в следующих целях: - рассмотрение заявки Пользователя на
        подключение юридическому лицу/индивидуальному предпринимателю услуг,
        предоставляемых АО «ForteBank», а также взаимодействие в целях сбора
        необходимых документов и сведений для заключения соответствующего
        договора с АО «ForteBank» и (или) его партнерами, консультирование о
        предоставляемых услугах, информирование о действующих и новых продуктах
        АО «ForteBank» и его партнеров. - осуществление технического управления
        сайтом, а также для проведения анализа и улучшения его работы.
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
        Способы и сроки обработки конфиденциальных данных
      </h2>
      <p>
        Обработка данных Пользователя осуществляется без ограничения срока любым
        законным способом, в том числе в информационных системах персональных
        данных с использованием средств автоматизации или без использования
        таких средств. Пользователь дает согласие на обработку конфиденциальных
        данных без ограничения срока. Данные Пользователя могут быть переданы
        уполномоченным органам государственной власти Республики Казахстан
        только по основаниям и в порядке, установленными законодательством
        Республики Казахстан. Администрация сайта обязана принимать необходимые
        организационные и технические меры для обеспечения конфиденциальности,
        целостности, сохранности и доступности конфиденциальных данных
        Пользователей, а также соблюдать иные требования к обработке данных,
        установленные законодательством Республики Казахстана. При утрате или
        разглашении конфиденциальных данных Администрация сайта информирует
        Пользователя об утрате или разглашении его данных. .Администрация сайта
        совместно с Пользователем принимает все необходимые меры по
        предотвращению убытков или иных отрицательных последствий, вызванных
        утратой или разглашением конфиденциальных данных Пользователя.
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">Обязательства сторон</h2>
      <p>
        Пользователь обязан: <br />

        Предоставить актуальные данные, запрашиваемые Сайтом в момент его
        использования. Обновить, дополнить предоставленные данные в случае
        изменения данной информации. Предварительно получить все необходимые
        согласия от третьих лиц в случае предоставления их данных Администрации
        сайта.
      </p>
      <p>
        Администрация сайта обязана: <br />

        Использовать полученные конфиденциальные данные исключительно для целей,
        указанных в Разделе 4 Политики конфиденциальности. Обеспечить хранение
        конфиденциальной информации в тайне, не разглашать ее без
        предварительного письменного разрешения Пользователя, а также не
        осуществлять продажу, обмен, опубликование, либо разглашение иными
        возможными способами конфиденциальных данных, за исключением случаев,
        предусмотренных Политикой конфиденциальности, заключенными договорами с
        клиентами, законодательством Республики Казахстан. Принимать меры
        предосторожности для защиты конфиденциальности данных Пользователя
        согласно порядку, обычно используемого для защиты такого рода информации
        в существующем деловом обороте, и в соответствии с требованиями
        законодательства Республики Казахстан.
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">Ответственность сторон</h2>
      <p>
        Администрация сайта, не исполнившая свои обязательства, несёт
        ответственность за убытки, понесённые Пользователем в связи с
        неправомерным использованием конфиденциальных данных, в соответствии с
        законодательством Республики Казахстан, за исключением случаев,
        предусмотренных п.7.2. настоящей Политики Конфиденциальности. В случае
        утраты или разглашения Конфиденциальной информации Администрация сайта
        не несёт ответственность, если данная конфиденциальная информация: Стала
        публичным достоянием до её утраты или разглашения. Была получена от
        третьей стороны до момента её получения Администрацией сайта. Была
        разглашена с согласия Пользователя. Пользователь возмещает АО
        «ForteBank» в полном объеме ущерб в случае предъявления третьими лицами,
        в том числе государственными органами, к АО «ForteBank» претензий,
        исков, касающихся Обработки Персональных данных, переданных
        Пользователем Администрации сайта без согласия субъекта персональных
        данных, Обработки недостоверных/искаженных/неактуальных Персональных
        данных по вине Пользователя.
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">Разрешение споров</h2>
      <p>
        До обращения в суд с иском по спорам, возникающим из отношений между
        Пользователем сайта
        <a href="#" class="payment-mail"> https://tahit.kz </a> и Администрацией
        сайта, обязательным является предъявление претензии (письменного
        предложения о добровольном урегулировании спора). Получатель претензии в
        течение 30 (Тридцати) календарных дней со дня получения претензии,
        письменно уведомляет заявителя претензии о результатах рассмотрения
        претензии. При не достижении соглашения спор будет передан на
        рассмотрение в судебный орган в соответствии с действующим
        законодательством Республики Казахстан. К настоящей Политике
        конфиденциальности и отношениям между Пользователем и Администрацией
        сайта применяется действующее законодательство Республики Казахстан.
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">Дополнительные условия</h2>
      <p>
        Обработка персональных данных осуществляется с момента заключения
        договоров АО «ForteBank» с клиентами и регулируется их условиями.
        Пользователь/субъект персональных данных в любое время может отозвать
        согласие на Обработку Персональных данных посредством направления
        письменного заявления АО «ForteBank» по адресу: г. Астана, ул. Достык,
        8/1. В указанном случае Администрация сайта может продолжить
        обрабатывать Персональные данные без согласия Пользователя/субъекта
        Персональных данных в случаях, установленных законодательством
        Республики Казахстан. Пользователь/субъект персональных данных имеет
        право реализовать свои права, предусмотренные Главой 4 Закона Республики
        Казахстан от 21 мая 2013 года № 94-V «О персональных данных и их
        защите», в том числе получить информацию, касающуюся Обработки
        персональных данных, потребовать уточнения или блокирования, или
        уничтожения Персональных данных в случаях, предусмотренных законом,
        принимать меры по защите своих прав. Взаимодействие осуществляется в
        письменной форме по адресу, указанному в п. 9.2. Политики
        конфиденциальности. К настоящей Политике конфиденциальности и отношениям
        между Пользователем и Администрацией сайта, возникающим в связи с
        применением Политики конфиденциальности, подлежит применению право
        Республики Казахстан. Оператор вправе вносить изменения в настоящую
        Политику конфиденциальности без согласия Пользователя. Новая Политика
        конфиденциальности вступает в силу с момента ее размещения на сайте
        <a href="#" class="payment-mail"> https://tahit.kz </a> если иное не
        предусмотрено новой редакцией Политики конфиденциальности. Пользователь
        обязуется самостоятельно следить за изменениями Политики
        конфиденциальности путем ознакомления с актуальной редакцией. Все
        предложения или вопросы по настоящей Политике конфиденциальности следует
        сообщать посредством направления обращения с использованием Сайта.
        Политика конфиденциальности размещена на странице по адресу<a
          href="#"
          class="payment-mail"
        >
          https://tahit.kz/payment.</a
        >
      </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.payment {
  padding-top: 30px;
  padding-bottom: 30px;

  &-box {
    padding: 50px 0;
    border-bottom: 1px solid #a5aab5;
  }
  &__h2 {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    color: #1d393c;
    text-align: start;
  }
  &-flex {
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &-mail {
    color: #174baf;
  }
  &-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &-list {
    list-style-type: disc;
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    margin: 20px 0;
    text-align: start;
    &__number {
      list-style-type: decimal;
      font-family: "Lato";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #333333;
      margin: 20px 0;
      text-align: start;
    }
  }
}

p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
  margin: 20px 0;
  text-align: start;
}

br {
  display: block;
  margin: 5px 0;
}

li {
  margin: 5px 0;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.mt-50 {
  margin-top: 50px;
}

.my-5 {
  margin: 5px 0;
}
</style>
